import Web3 from "web3";
import { ROUTER_ABI } from "../../contracts/pancakeABI";
import axios from "axios";

const web3 = new Web3("https://bsc-dataseed2.binance.org");
const routerAddress = "0x10ED43C718714eb63d5aA57B78B54704E256024E";
const slothPair = "0x59dc1667b78739badbef251e9bbcd9d32209b535";

const routerContract = new web3.eth.Contract(ROUTER_ABI, routerAddress);

export const getBnbToUsdPrice = async () => {
  const bnbAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  const usdtAddress = "0x55d398326f99059fF775485246999027B3197955";
  try {
    const amounts = await routerContract.methods
      .getAmountsOut(web3.utils.toWei("1"), [bnbAddress, usdtAddress])
      .call();
    return amounts[1] / 10 ** 18;
  } catch (error) {
    return 0;
  }
};

export const getTokenToUsdPrice = async (tokenAddress, tokenDecimals) => {
  const bnbAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";

  const amounts = await routerContract.methods
    .getAmountsOut(web3.utils.toWei("1"), [bnbAddress, tokenAddress])
    .call();
  const tokenPrice = amounts[1] / 10 ** tokenDecimals;
  return tokenPrice;
};

export const getSlothToUsdPrice = async (slothDecimals) => {
  const url = `https://api.dexscreener.com/latest/dex/pairs/bsc/${slothPair}`;
  try {
    const response = await axios.get(url);
    const slothToUsdPrice = response.data.pair.priceUsd;
    return slothToUsdPrice;
  } catch (error) {
    return 0;
  }
};

export const getSlothTxn = async (slothDecimals) => {
  const url = `https://api.dexscreener.com/latest/dex/pairs/bsc/${slothPair}`;

  const response = await axios.get(url);
  const totalBuysTransaction = response.data.pair.txns.h24.buys;
  const totalSellsTransaction = response.data.pair.txns.h24.sells;
  return {
    totalBuysTransaction,
    totalSellsTransaction,
  };
};
