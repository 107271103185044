import { db } from "../../lib/init-firebase";
import { collection, getDocs } from "firebase/firestore";

const getAnnouncement = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "announcement"));

    let currentAnnouncement = "";
    let currentLink = "";

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      currentAnnouncement = data.announcement;
      currentLink = data.link;
    });
    //set
    return { currentAnnouncement, currentLink };
  } catch (error) {
    console.error("Error retrieving data:", error);
    return null;
  }
};

export default getAnnouncement;
