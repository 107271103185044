import Web3 from "web3";
import { ROUTER_ABI } from "../../contracts/pancakeABI";
import axios from "axios";

const web3 = new Web3("https://bsc-dataseed2.binance.org");
const routerAddress = "0x10ED43C718714eb63d5aA57B78B54704E256024E";
const slothPair = "0x59dc1667b78739badbef251e9bbcd9d32209b535";

export const checkConnection = async () => {
  const bnbAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  const usdtAddress = "0x55d398326f99059fF775485246999027B3197955";
  const url = `https://api.dexscreener.com/latest/dex/pairs/bsc/${slothPair}`;

  try {
    const routerContract = new web3.eth.Contract(ROUTER_ABI, routerAddress);
    const amounts = await routerContract.methods
      .getAmountsOut(web3.utils.toWei("1"), [bnbAddress, usdtAddress])
      .call();
    const response = await axios.get(url);
    const slothToUsdPrice = response.data.pair.priceUsd;
    return 1;
  } catch (error) {
    return 0;
  }
};
