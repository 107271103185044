import React, { useState } from "react";
import Modal from "react-modal";
import "./ConnectionError.css";

const ConnectionError = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleRefresh = () => {
    window.location.reload(); // Refresh the page
  };

  return (
    <>
      <button className="disclaimer-button" onClick={openModal}>
        Disclaimer
      </button>
      <Modal isOpen={isModalOpen} className="modal" overlayClassName="overlay">
        <div className="error-message">
          <div className="error">
            Connection Error, Please REFRESH the page!
          </div>
          <button className="refresh-button" onClick={handleRefresh}>
            Refresh
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ConnectionError;
