import axios from "axios";
import { Buffer } from "buffer-es";

async function getRewards(
  walletBalance,
  volume,
  circulationSupply,
  bnbPrice,
  tokenPrice
) {
  try {
    const rewardsResults = {
      holdingDecimals: 0,
      holdingPercentage: 0,
      holdingRewardsUsd: 0,
      rewardsHoldingsPercentage: 0,
      holdingRewardsToken: 0,
      usdRewards: 0,
      tokenRewards: 0,
    };

    rewardsResults.usdRewards = (volume * 0.03).toFixed(2);
    rewardsResults.tokenRewards = (
      rewardsResults.usdRewards / tokenPrice
    ).toFixed(0);

    rewardsResults.holdingDecimals = walletBalance / circulationSupply;
    rewardsResults.holdingPercentage = (
      rewardsResults.holdingDecimals * 100
    ).toFixed(2);
    rewardsResults.holdingRewardsToken = (
      rewardsResults.tokenRewards * rewardsResults.holdingDecimals
    ).toFixed(0);
    rewardsResults.holdingRewardsUsd = (
      rewardsResults.holdingRewardsToken * tokenPrice
    ).toFixed(2);

    rewardsResults.rewardsHoldingsPercentage = (
      (rewardsResults.holdingRewardsToken / walletBalance) *
      100
    ).toFixed(2);

    return rewardsResults;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

export default getRewards;
