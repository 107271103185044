import Web3 from "web3";

const web3 = new Web3("https://bsc-dataseed2.binance.org");
const zero = "0x000000000000000000000000000000000000dead";

export const checkWalletBalance = async (
  contractAddress,
  walletAddress,
  decimals
) => {
  try {
    const tokenAbi = [
      {
        constant: true,
        inputs: [{ name: "_owner", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "balance", type: "uint256" }],
        type: "function",
      },
    ];
    const tokenContract = new web3.eth.Contract(tokenAbi, contractAddress);

    // Get token balance
    const tokenResult = await tokenContract.methods
      .balanceOf(walletAddress)
      .call();
    const tokenBalance = Math.floor(tokenResult / 10 ** decimals);

    const zeroTokenResult = await tokenContract.methods.balanceOf(zero).call();
    const zeroAddress = Math.floor(zeroTokenResult / 10 ** decimals);

    // Get BNB balance
    const bnbResult = await web3.eth.getBalance(walletAddress);
    const bnbBalance = Number(web3.utils.fromWei(bnbResult, "ether")).toFixed(
      4
    );

    const network = 1;

    return { tokenBalance, bnbBalance, zeroAddress, network };
  } catch (error) {
    const network = 0;
    return { network };
  }
};
