import React, { createContext, useState, useEffect } from "react";

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [connectedWallet, setConnectedWallet] = useState("");

  const connectWallet = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((accounts) => {
          setConnectedWallet(accounts[0]);
          localStorage.setItem("connectedWallet", accounts[0]); // Store the connected wallet address in local storage
        })
        .catch((error) => {
          console.log("Error connecting with Metamask:", error);
        });
    } else {
      console.log("Metamask not installed");
    }
  };

  const disconnectWallet = () => {
    setConnectedWallet("");
    localStorage.removeItem("connectedWallet"); // Remove the connected wallet address from local storage
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        setConnectedWallet(accounts[0]);
        localStorage.setItem("connectedWallet", accounts[0]); // Update the connected wallet address in local storage when it changes
      });
    }

    // Retrieve the connected wallet address from local storage on component mount
    const storedWallet = localStorage.getItem("connectedWallet");
    if (storedWallet) {
      setConnectedWallet(storedWallet);
    }
  }, []);

  return (
    <WalletContext.Provider
      value={{ connectedWallet, connectWallet, disconnectWallet }}
    >
      {children}
    </WalletContext.Provider>
  );
};
