import React, { useState, useEffect } from "react";
import "./AnnBanner.css";
import getAnnouncement from "../fetch/announcement";

const AnnBanner = () => {
  const [showBanner, setShowBanner] = useState(true);
  const [announcement, setAnnouncement] = useState("");
  const [link, setLink] = useState("")

  useEffect(() => {
    if (showBanner === true) {
      const fetchData = async () => {
        const currentAnnouncement = await getAnnouncement();

        setAnnouncement(currentAnnouncement.currentAnnouncement);
        setLink(currentAnnouncement.currentLink);
      };

      const intervalId = setInterval(() => {
        fetchData();
      }, 3000);

      return () => clearInterval(intervalId);
    } // Cleanup function to clear the interval when the component unmounts
  }, []);

  const handleDismiss = () => {
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className="announcement-wrapper">
      <a
        href={link}
        className="announcement-text"
        target="_blank"
        rel="noreferrer"
      >
        {announcement}
      </a>
      <button onClick={handleDismiss} className="dismiss-button">
        x
      </button>
    </div>
  );
};

export default AnnBanner;
