import { db } from "../../lib/init-firebase";
import { collection, getDocs } from "firebase/firestore";
import axios from "axios";

const get24hVolume = async () => {
  const slothPair = "0x59dc1667b78739badbef251e9bbcd9d32209b535";

  try {
    // Get the query snapshot of the "data" collection
    try {
      const querySnapshot = await getDocs(collection(db, "data"));
      const url = `https://api.dexscreener.com/latest/dex/pairs/bsc/${slothPair}`;

      const response = await axios.get(url);

      console.log(response.data.pair);

      const volume24 = response.data.pair.volume.h24;
      const txns =
        response.data.pair.txns.h24.buys + response.data.pair.txns.h24.sells;

      // Define the result object
      const result = {
        totalSells: 0,
        totalBuys: 0,
        totalAmount: 0,
        numberOfTransactions: 0,
        sortedLeaderboardSells: [],
        sortedLeaderboardBuys: [],
        network: 1,
      };

      querySnapshot.forEach((doc) => {
        const data = doc.data();

        const totalSells = data.totalSells;
        const totalBuys = data.totalBuys;
        const totalAmount = volume24;
        const numberOfTransactions = txns;

        result.totalSells += totalSells;
        result.totalBuys += totalBuys;
        result.totalAmount += totalAmount;
        result.numberOfTransactions += numberOfTransactions;

        const leaderboardSells = Object.entries(
          data.sortedLeaderboardSells
        ).map(([address, amount]) => {
          return { address, amount: parseFloat(amount) };
        });

        const leaderboardBuys = Object.entries(data.sortedLeaderboardBuys).map(
          ([address, amount]) => {
            return { address, amount: parseFloat(amount) };
          }
        );

        result.sortedLeaderboardSells.push(...leaderboardSells);
        result.sortedLeaderboardBuys.push(...leaderboardBuys);
      });

      result.sortedLeaderboardSells.sort((a, b) => b.amount - a.amount);

      result.sortedLeaderboardBuys.sort((a, b) => b.amount - a.amount);

      return result;
    } catch (error) {
      const result = {
        network: 0,
      };
      return result;
    }
  } catch (error) {
    console.error("Error retrieving data:", error);
    return null;
  }
};

export default get24hVolume;
