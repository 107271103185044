import React, { useState } from "react";
import Modal from "react-modal";
import "./DisclaimerModal.css";

const DisclaimerModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button className="disclaimer-button" onClick={openModal}>
        Disclaimer
      </button>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Disclaimer</h2>
        <p>
          - To ensure accurate and reliable data, we have implemented an
          automated update system for all numerical values in our application.
          These values are refreshed at regular intervals of 5 minutes. This
          interval has been carefully chosen to strike a balance between
          providing up-to-date information and maintaining the stability and
          performance of our server.
        </p>
        <p>
          - This information is not financial advice. The content provided on
          this website is for informational purposes only and should not be
          considered as financial or investment advice. The information
          presented is based on the current volume and market conditions, and it
          may not accurately reflect the actual figures or performance of any
          assets or investments.
        </p>
        <p>
          - All numerical values displayed, including balances, values, rewards,
          and percentages, are estimates based on the available data and may
          vary. These estimates are subject to change and should not be relied
          upon for making financial decisions. We recommend consulting with a
          professional financial advisor before making any investment decisions.
        </p>
        <p>
          - The leaderboard displayed on this platform is for visual purposes
          only and does not entail any rewards, prizes, or official rankings. It
          is purely designed to enhance the user experience and does not reflect
          any monetary value or competitive standings.
        </p>
        <p>
          For further information or any inquiries, please contact our Telegram
          account:{" "}
          <a className="link-to-tg" href="https://t.me/DevSloth">
            Sloth Developer
          </a>
          .
        </p>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </>
  );
};

export default DisclaimerModal;
