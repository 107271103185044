import React, { useState } from "react";
import "./App.css";
import Header from "./components/Header/Header.js";
import MainComponent from "./components/MainComponent/mainComponent";
import Footer from "./components/Footer/Footer";
import AnnBanner from "./components/AnnouncementBanner/AnnBanner";

function App() {
  const [theme, setTheme] = useState("light");

  const toggleDarkMode = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  console.log(theme);

  return (
    <div className="App">
      <AnnBanner />
      <Header theme={theme} toggleTheme={toggleDarkMode} />
      <MainComponent theme={theme} />
      <Footer />
    </div>
  );
}

export default App;
