import React, { useState } from "react";
import "./Footer.css";
import { FaGlobe, FaTelegram, FaTwitter } from "react-icons/fa";
import DisclaimerModal from "../DisclaimerModal/DisclaimerModal.js";

const Footer = () => {
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false);

  const openDisclaimerModal = () => {
    setIsDisclaimerModalOpen(true);
  };
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="social-links">
          <a
            href="https://slothmeme.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGlobe className="social-icon" />
          </a>
          <a
            href="https://t.me/slothmeme"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTelegram className="social-icon" />
          </a>
          <a
            href="https://twitter.com/SlothMeme_BSC"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="social-icon" />
          </a>
        </div>
        <div className="copyright">
          &copy; {new Date().getFullYear()} SLOTH. All rights reserved.
        </div>
      </div>
      <DisclaimerModal className="disclaimer-but" onClick={openDisclaimerModal}>
        Disclaimer
      </DisclaimerModal>
    </footer>
  );
};

export default Footer;
