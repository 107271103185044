import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { WalletProvider } from "./components/fetch/WalletContext";
import { ThemeProvider } from "./components/ThemeContext/ThemeContext";

ReactDOM.render(
  <WalletProvider>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </WalletProvider>,
  document.getElementById("root")
);

reportWebVitals();
