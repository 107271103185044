import React from "react";

const DexScreenerEmbed = ({ theme }) => {

  const containerStyle = {
    width: "100%",
    height: "100%",
    border: "0px solid #ccc",
    borderRadius: "15px",
    overflow: "hidden",
  };

  const iframeStyle = {
    width: "100%",
    height: "100%",
    border: "none",
  };

  const embedUrl = `https://dexscreener.com/bsc/0x59Dc1667B78739badBef251e9bbCd9d32209B535?embed=1&theme=${theme}&trades=1&info=0`;

  return (
    <div id="dexscreener-embed" style={containerStyle}>
      <iframe
        src={embedUrl}
        title="DexScreener Embed"
        style={iframeStyle}
      ></iframe>
    </div>
  );
};

export default DexScreenerEmbed;
